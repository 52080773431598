<template>
	<div>
		<h2 v-focus tabindex="0" class="sr-only">統計摘要</h2>
		<tab :headinglevel="3" :data="tabData">
			<template #increase>
				<table-div :header="increaseHeader" :datas="increaseDatas">
					<template #date="{ item }">
						<div>{{ item.slice(0, 4) }}年{{ item.slice(5,7) }}月</div>
					</template>
				</table-div>
			</template>
			<template #cumsum>
				<table-div :header="cumsumHeader" :datas="cumsumDatas">
					<template #date="{ item }">
						<div>{{ item.slice(0, 4) }}年{{ item.slice(5,7) }}月</div>
					</template>
				</table-div>
			</template>
		</tab>
	</div>
</template>

<script>
import axios from "axios";
import { host } from "@/api/base";
import { mapState } from "vuex";
import tableDiv from "@/components/tableDiv.vue";
import tab from "@/components/tab.vue";

export default {
	components: {
		tab,
		tableDiv
	},
	data() {
		return {
			url: `${host}api/statistics/summary/`,
			tabData: [
				{
					display_name: "月累積",
					value: "cumsum",
					type: "cumsum",
					data: []
				},
				{
					display_name: "月新增",
					value: "increase",
					type: "increase",
					data: []
				},
			],
			increaseHeader: {
				"date": "時間",
				"make-part": "校對段數",
				"borrowed": "借閱數",
				"epub-download": "epub 下載數",
				"txt-download": "txt 下載數",
				"book-upload": "總書數",
				"self-upload": "掃描書數",
				"epub-upload": "epub 書數",
				"txt-upload": "txt 書數",
				"edited-book": "校對書數",
				"editor-join": "志工人數",
				"guest-join": "視障者人數",
				"editor-active": "最後校對",
			},
			increaseDatas: [],
			cumsumHeader: {
				"date": "時間",
				"make-part-cumsum": "校對段數",
				"borrowed-cumsum": "借閱數",
				"epub-download-cumsum": "epub 下載數",
				"txt-download-cumsum": "txt 下載數",
				"book-upload-cumsum": "總書數",
				"self-upload-cumsum": "掃描書數",
				"epub-upload-cumsum": "epub 書數",
				"txt-upload-cumsum": "txt 書數",
				"edited-book-cumsum": "校對書數",
				"editor-join-cumsum": "志工人數",
				"guest-join-cumsum": "視障者人數",
			},
			cumsumDatas: [],
		};
	},
	computed: {
		...mapState("user", ["user"])
	},
	mounted() {
		this.getDatas();
	},
	methods: {
		getDatas() {
			this.datas = [];
			axios.get(this.url, {}).then(res => {
				let temp = [];
				_.each(res.data, v => {
					temp.push(v);
				});
				this.increaseDatas = temp;
				this.cumsumDatas = temp;
			});
		}
	}
};
</script>

<style lang="scss" scoped>
img {
	width: 100%;
}
</style>
